import React from 'react';
import { Box, Button, Link } from '@mui/material';

export default function UnderDev() {
  return (
    <div
      style={{
        position: 'absolute',
        left: '50%',
        top: '40%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Box>
        <h4>Oh no!</h4>
        <h5>You reacted a page that is still under development.</h5>

        <h5>Let us know what features you want see below.</h5>
        <Button variant="contained">
          <Link href="/feedback" color="inherit" rel="noopener" target="_blank">
            Feedback form
          </Link>
        </Button>
      </Box>
    </div>
  );
}
