import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '../Reusable/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import makeStyles from '@mui/styles/makeStyles';
import { API_URL } from '../config';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import './Driver.css';

// Icons
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { Link } from '@mui/material';

const useStyles = makeStyles({
  leftText: {
    textAlign: 'left',
  },
  rightText: {
    textAlign: 'right',
  },
  centerText: {
    textAlign: 'center',
  },
});
const UpdatePhone = () => {
  const { currentUser } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');
  const history = useHistory();
  const classes = useStyles();

  function handleSubmit(e) {
    e.preventDefault();
    axios
      .post(API_URL + '/driver/profile/updatephone/', {
        uid: currentUser.uid,
        phone: phoneNumber,
      })
      .then((response) => {
        setLoading(false);
        history.push('/driver/verifynumber');
      })
      .catch((error) => {
        console.log(error.response?.data?.message);
        setError('Incorrect code. Please try again.');
        setLoading(false);
      });
  }

  return (
    <Container maxWidth="sm">
      <Grid container spacing={1}>
        <Grid item xs={8}>
          {/* TODO: Center the text vertically without breaks  */}
          <br />
          <br />
          <Typography component="h1" variant="h2">
            <b>Verify</b>
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.centerText}>
          <img
            style={{ maxWidth: '130px', padding: 0.2, alignSelf: 'center' }}
            src={'/ridejamlogo.png'}
            alt="Logo"
          />
        </Grid>
        <Grid item xs={12}>
          <br />
          <Typography>Please enter your updated phone number below.</Typography>
          <br />
        </Grid>
        <br />
        {error !== '' && (
          <Grid item xs={12}>
            <Alert severity="error">{error}</Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            value={phoneNumber}
            margin="normal"
            required
            fullWidth
            id="phoneNumber"
            label="Phone Number"
            name="Phone Number"
            autoComplete="tel"
            autoFocus={true}
            icon={<SmartphoneIcon />}
            variant="outlined"
            onChange={(e) => setPhoneNumber(e.target.value)}
            onSubmit={handleSubmit}
          />
        </Grid>
        <Grid item xs={12} className={classes.centerText}>
          <br />
          <Link href="/driver/verifynumber">
            <Typography>Return without updating phone number</Typography>
          </Link>
        </Grid>
        <Grid item xs={12} className={classes.centerText}>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Button
            type="submit"
            size="large"
            variant="contained"
            disabled={phoneNumber.length < 10}
            style={{
              maxWidth: '400px',
              maxHeight: '400px',
              minWidth: '150px',
              minHeight: '55px',
              textTransform: 'none',
            }}
            color="primary"
            onClick={handleSubmit}
          >
            <Typography component="h3" variant="h5">
              <b>Update</b>
            </Typography>
          </Button>
        </Grid>
        <br />
        <br />
      </Grid>
    </Container>
  );
};

export default UpdatePhone;
