import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Link, useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '../Reusable/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import makeStyles from '@mui/styles/makeStyles';
import { API_URL } from '../config';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import './Driver.css';

import MailPackage from './MailPackage';
// Icons
import SmartphoneIcon from '@mui/icons-material/Smartphone';

const useStyles = makeStyles({
  leftText: {
    textAlign: 'left',
  },
  rightText: {
    textAlign: 'right',
  },
  centerText: {
    textAlign: 'center',
  },
});

const CompleteProfile = () => {
  const { signup, currentUser, profile } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const classes = useStyles();

  console.log(profile);

  return (
    <Container maxWidth="sm">
      <Grid container spacing={1}>
        <Grid item xs={8}>
          {/* TODO: Center the text vertically without breaks  */}
          <br />
          <br />
          <Typography component="h1" variant="h2">
            <b>Welcome</b>
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.centerText}>
          <img
            style={{ maxWidth: '130px', padding: 0.2, alignSelf: 'center' }}
            src={'/ridejamlogo.png'}
            alt="Logo"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography color="primary" variant="h4">
            Hi, {profile.firstname} {profile.lastname}! Welcome to RideJam!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Driver ID: <b>{profile?.username?.toUpperCase()}</b>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>
            Profile URL:{' '}
            <a
              rel="noopener noreferrer"
              href={`https://rdj.app?d=${profile.username}`}
              target="_blank"
            >
              https://rdj.app?d={profile.username}
            </a>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Lets get your free welcome package, it includes instructions to get
            started and instructions for your passengers.
          </Typography>
        </Grid>
        <br />
        <br />
        {error !== '' && (
          <Grid item xs={12}>
            <Alert severity="error">{error}</Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <MailPackage
            setError={setError}
            setLoading={setLoading}
            loading={loading}
          />
        </Grid>

        <Grid item xs={12} className={classes.centerText}>
          <Button
            type="submit"
            size="large"
            variant="link"
            disabled={loading}
            style={{
              textTransform: 'none',
            }}
            color="primary"
            href="/driver"
          >
            <Typography component="body" variant="body" color="primary">
              <b>Skip</b>
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={12} className={classes.centerText}></Grid>
      </Grid>
    </Container>
  );
};
export default CompleteProfile;
