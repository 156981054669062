import React, { useState } from 'react';
import Button from '@mui/material/Button';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import { Grid, Typography } from '@mui/material';
import TextField from '../Reusable/TextField';
import { API_URL } from '../config';

import BusinessIcon from '@mui/icons-material/Business';
import Home from '@mui/icons-material/Home';
import { MailOutline, Language, Map } from '@mui/icons-material';

import { useHistory } from 'react-router-dom';

const MailPackage = ({ setError, setLoading, loading }) => {
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const { currentUser } = useAuth();
  const [apartment, setApartment] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');

  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();
    const completeAddress = {
      address: address,
      suite: apartment,
      city: city,
      state: state,
      zip: zip,
      uid: currentUser.uid,
    };
    console.log(completeAddress);
    try {
      const response = await axios.post(
        API_URL + '/driver/address/',
        completeAddress
      );
      console.log(response);
      history.push('/driver');
    } catch (e) {
      console.log('error = ', e?.response?.data?.message);
      setError(e?.response?.data?.message);
      setLoading(false);
      return;
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Address"
              name="address"
              placeholder="Address"
              margin="normal"
              icon={<Home />}
              /*  value={firstName || ""}
                        onChange={handleChange}
                        error={!!formErrors.firstName}
                        helperText={formErrors.firstName} */
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Apartment, Suite, etc"
              name="apartment"
              placeholder="Apartment, Suite, etc"
              margin="normal"
              icon={<BusinessIcon />}
              /* value={lastName || ""}
                        onChange={handleChange}
                        error={!!formErrors.lastName}
                        helperText={formErrors.lastName} */
              onChange={(e) => setApartment(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="City"
              name="city"
              placeholder="City"
              margin="normal"
              icon={<Map />}
              /*             value={username || ""}
                        onChange={handleChange}
                        error={!!formErrors.username}
                        helperText={formErrors.username} */
              onChange={(e) => setCity(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="State"
              name="state"
              placeholder="State"
              icon={<Language />}
              /*             value={email || ""}
                        onChange={handleChange}
                        margin="normal"
                        error={!!formErrors.email}
                        helperText={formErrors.email} */
              onChange={(e) => setState(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="ZIP"
              name="zip"
              placeholder="ZIP Code"
              icon={<MailOutline />}
              /*           value={phone || ""}
                        onChange={handleChange}
                        error={!!formErrors.phone}
                        helperText={formErrors.phone} */
              margin="normal"
              onChange={(e) => setZip(e.target.value)}
              required
            />
          </Grid>
        </Grid>
        <div
          style={{ display: 'flex', marginTop: 50, justifyContent: 'center' }}
        >
          <Button
            type="submit"
            size="large"
            variant="contained"
            disabled={loading}
            style={{
              maxWidth: '400px',
              maxHeight: '400px',
              minWidth: '100px',
              minHeight: '50px',
              textTransform: 'none',
            }}
            color="primary"
          >
            <Typography component="h3" variant="h5">
              <b>Mail Welcome Package</b>
            </Typography>
          </Button>
        </div>
      </form>
    </>
  );
};

export default MailPackage;
