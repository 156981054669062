import React from 'react';

export default function Legal() {
  return (
    <div>
      Terms and Conditions Last updated: 09/29/2021 Please read these Terms and
      Conditions carefully before using the RideJam.app website. Your access to
      and use of the service is conditioned on your acceptance of and compliance
      with these Terms. These Terms apply to all visitors, users and others who
      access or use the Service. By accessing or using RideJam.app you agree to
      be bound by these Terms. RideJam.app is not affiliated with Spotify, Apple
      music, or any other music service. RideJam.app may access and use
      information from your Spotify account, but RideJam.app will never store
      your password. RideJam.app has no control over, and assumes no
      responsibility for, the content, privacy policies, or practices of any
      third party web sites or services. You further acknowledge and agree that
      RideJam shall not be responsible or liable, directly or indirectly, for
      any damage or loss caused or alleged to be caused by or in connection with
      use of or reliance on any such content, goods or services available on or
      through any such web sites or services. RideJam.app reserves the right to
      contact you through the phone number and email address that you provide.
      Additionally, RideJam.app reserved the right to control the music being
      played while using RideJam.app. RideJam.app reserves the right, at our
      sole discretion, to modify or replace these Terms at any time. If you have
      any questions about these Terms, please contact us.
    </div>
  );
}
