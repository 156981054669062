import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Albums from '../components/Albums';

import { Fab } from '@mui/material';

export default function ExploreCategory({
  details,
  getSpotifyData,
  setCurrentMusicView,
  setLocation,
}) {
  const [categoryData, setCategoryData] = useState();

  useEffect(() => {
    async function fetchData() {
      if (!categoryData && details.id) {
        getSpotifyData('category', details.id, false, setCategoryData);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details]);

  return (
    <>
      <Box zIndex="modal" position="fixed" top={10} left={10}>
        <Fab color="default" size="small" onClick={() => window.history.back()}>
          <ArrowBackIcon />
        </Fab>
      </Box>
      <Box m={0} display="flex" flexDirection="column" alignItems="center">
        <Box fontWeight="fontWeightBold" m={1} p={0} fontSize="h5.fontSize">
          {details?.name || <Skeleton />}
        </Box>
        <Box p={1} fontSize="fontSize">
          {details?.description}
        </Box>
      </Box>
      <Box fullWidth>
        <Albums
          verticle={true}
          setCurrentMusicView={setCurrentMusicView}
          setLocation={setLocation}
          albums={categoryData?.playlists}
        />
      </Box>
      <br />
      <br />
    </>
  );
}
