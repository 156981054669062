import React, { useState, useEffect } from 'react';

// material ui
import {
  Button,
  Box,
  Paper,
  Grid,
  Typography,
  LinearProgress,
  Slide,
  Dialog,
  Toolbar,
  IconButton,
  Fab,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
// import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
// import PauseIcon from '@mui/icons-material/Pause';
import { Refresh } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NowPlaying({
  getSpotifyData,
  nowPlaying,
  setNowPlaying,
}) {
  //TODO: #13 add queue to this area when full screen
  const classes = useStyles();
  const [progressCounter, setProgressCounter] = useState(0);
  const [fullScreen, setFullScreen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refreshDisabled, setRefreshDisabled] = useState(false);

  useEffect(() => {
    getSpotifyData('nowPlaying', '', false, setNowPlaying);
    function updateProgress() {
      if (nowPlaying?.is_playing !== false) {
        setProgressCounter((temp) => temp + 1000);
      }
    }
    // TODO #12 figure out how to kill other intervals if they are running. During hot reloading it fires a million times
    setInterval(updateProgress, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setProgressCounter(0);
  }, [nowPlaying]);

  useEffect(() => {
    if (
      nowPlaying?.progress_ms + progressCounter <
        nowPlaying?.item?.duration_ms + 7000 &&
      nowPlaying?.progress_ms + progressCounter - 4000 >
        nowPlaying?.item?.duration_ms
    ) {
      console.log('song over');
      getSpotifyData('nowPlaying', '', false, setNowPlaying);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progressCounter]);

  if (!nowPlaying?.item) {
    return (
      <div>
        <Paper fullWidth onClick={() => setFullScreen(true)}>
          <Grid
            container
            s={1}
            direction="row"
            justifyContent="center"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item xs={12}>
              <Typography noWrap>
                <b>Driver disconencted</b>
                <br />
                Click here for details
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <Dialog
          fullScreen
          open={fullScreen}
          onClose={() => setFullScreen(false)}
          TransitionComponent={Transition}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setFullScreen(false)}
              aria-label="close"
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <Box m={2}>
            Your drivers Spotify has been disconnected. To recconnect ask your
            driver to open Spotify and resume playing.
          </Box>
          <Box m={0} sx={{ textAlign: 'center' }}>
            <Button
              onClick={() => {
                getSpotifyData('nowPlaying', '', false, setNowPlaying);
                setRefreshDisabled(true);
                setTimeout(() => {
                  setRefreshDisabled(false);
                }, 5000);
              }}
              variant="contained"
              disabled={refreshDisabled}
            >
              <Refresh /> &nbsp; Refresh
            </Button>
          </Box>
        </Dialog>
      </div>
    );
  }

  return (
    <div>
      <Paper fullWidth onClick={() => setFullScreen(true)}>
        <Grid
          container
          s={1}
          direction="row"
          justifyContent="center"
          alignItems="center"
          wrap="nowrap"
        >
          <Grid item xs={2} sm={1}>
            <img
              alt=""
              style={{ width: '50px' }}
              src={nowPlaying?.item?.album?.images[0]?.url}
            />
          </Grid>
          <Grid item xs={10} sm={11}>
            <Typography noWrap>
              <b>{nowPlaying?.item?.name}</b>
              <br />
              {nowPlaying?.item?.artists?.map((artist, idx) =>
                idx === 0 ? artist.name : ' & ' + artist.name
              )}
            </Typography>
          </Grid>
        </Grid>
        <LinearProgress
          fullWidth
          variant="determinate"
          value={
            ((nowPlaying?.progress_ms + progressCounter) /
              nowPlaying?.item?.duration_ms) *
            100
          }
        />
      </Paper>
      <Dialog
        fullScreen
        open={fullScreen}
        onClose={() => setFullScreen(false)}
        TransitionComponent={Transition}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setFullScreen(false)}
            aria-label="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <Box
          m={2}
          sx={{ textAlign: 'center', alignContent: 'center', mx: 'auto' }}
        >
          <Box width="50%" sx={{ marginLeft: 'auto', marginRight: 'auto' }}>
            <img
              alt=""
              style={{ width: '100%' }}
              src={nowPlaying?.item?.album?.images[0]?.url}
            />
          </Box>
          <Box fontWeight="fontWeightBold" m={2} p={1} fontSize="h5.fontSize">
            <Typography noWrap>
              <b>{nowPlaying?.item?.name}</b>
              <br />
              {nowPlaying?.item?.artists?.map((artist, idx) =>
                idx === 0 ? artist.name : ' & ' + artist.name
              )}
            </Typography>
          </Box>
          <Box>
            <Fab
              disabled={loading}
              size="small"
              onClick={() => {
                setLoading(true);
                getSpotifyData('skip/previous', '', true, () =>
                  setLoading(false)
                );
              }}
            >
              <SkipPreviousIcon />
            </Fab>
            {/* <Fab
              size="medium"
              color="primary"
              disabled={loading}
              onClick={() => {
                setLoading(true);
                if (nowPlaying?.is_playing) {
                  getSpotifyData('pause', '', true, () => setLoading(false));
                  setTimeout(function () {
                    getSpotifyData('nowPlaying', '', false, setNowPlaying);
                  }, 30000);
                } else {
                  getSpotifyData('play', '', true, () => setLoading(false));
                }
              }}
            >
              {nowPlaying?.is_playing ? <PauseIcon /> : <PlayArrowIcon />}
            </Fab> */}
            <Fab
              onClick={() => {
                getSpotifyData('nowPlaying', '', false, setNowPlaying);
                setRefreshDisabled(true);
                setTimeout(() => {
                  setRefreshDisabled(false);
                }, 5000);
              }}
              color="primary"
              variant="contained"
              disabled={refreshDisabled}
            >
              <Refresh />
            </Fab>
            <Fab
              disabled={loading}
              size="small"
              onClick={() => {
                setLoading(true);
                getSpotifyData('skip/next', '', true, () => setLoading(false));
              }}
            >
              <SkipNextIcon />
            </Fab>
            <br />
            <br />
            <LinearProgress
              fullWidth
              variant="determinate"
              value={
                ((nowPlaying?.progress_ms + progressCounter) /
                  nowPlaying?.item?.duration_ms) *
                100
              }
            />
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}
