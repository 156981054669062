import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '../Reusable/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from '@mui/material';
import { API_URL } from '../config';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import './Driver.css';

// Icons
import LockIcon from '@mui/icons-material/Lock';
import EmailIcon from '@mui/icons-material/Email';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import PersonIcon from '@mui/icons-material/Person';

const useStyles = makeStyles({
  centerText: {
    textAlign: 'center',
  },
});

const Signup = () => {
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phonenumber, setPhoneNumber] = useState('');
  const { signup, currentUser } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  /*if (currentUser && !isLoading) {
    history.push('/driver');
  }*/

  async function handleSubmit(e) {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      let signupRes;
      try {
        // Check /checkfor/ to  see if email and username is in use
        const response = await axios.get(`${API_URL}/driver/checkfor/`, {
          params: {
            email: email,
            phonenumber: phonenumber,
          },
        });
        console.log('response', response);
      } catch (error) {
        setError(error.response.data.message);
        setLoading(false);
        console.log('error', error.response.data.message);
        return;
      }
      try {
        signupRes = await signup(email, password);
      } catch (e) {
        setError('Failed to create account');
        console.log('error = ', e);
        setLoading(false);
      }
      const requestOptions = {
        firstname: firstname,
        lastname: lastname,
        email: email,
        phonenumber: phonenumber,
        uid: signupRes.user.uid,
      };
      try {
        const response = await axios.post(
          API_URL + '/driver/create/',
          requestOptions
        );
        console.log(response);
      } catch (e) {
        setError(e?.response?.data?.message);
        console.log(e);
        console.log('error');
      }
      setLoading(false);
      history.push('/driver/verifynumber');
    }
  }
  return (
    <Container maxWidth="sm">
      <Grid container spacing={1}>
        <Grid item xs={8}>
          {/* TODO: Center the text vertically without breaks  */}
          <br />
          <br />
          <Typography component="h1" variant="h2">
            <b>Sign up</b>
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.centerText}>
          <img
            style={{ maxWidth: '130px', padding: 1, alignSelf: 'center' }}
            src={'/ridejamlogo.png'}
            alt="Logo"
          />
        </Grid>

        <br />
        {error !== '' && (
          <Grid item xs={12}>
            <Alert severity="error">{error}</Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            value={firstname}
            margin="normal"
            required
            fullWidth
            id="firstname"
            label="First Name"
            name="First Name"
            autoComplete="First Name"
            autoFocus={true}
            icon={<PersonIcon />}
            variant="outlined"
            onChange={(e) => setFirstName(e.target.value)}
            onSubmit={handleSubmit}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={lastname}
            margin="normal"
            required
            fullWidth
            id="lastname"
            label="Last Name"
            name="Last name"
            autoComplete="Last Name"
            autoFocus={false}
            icon={<PersonIcon />}
            variant="outlined"
            onChange={(e) => setLastName(e.target.value)}
            onSubmit={handleSubmit}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={email}
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus={false}
            icon={<EmailIcon />}
            variant="outlined"
            onChange={(e) => setEmail(e.target.value)}
            onSubmit={handleSubmit}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={password}
            margin="normal"
            variant="outlined"
            required
            name="password"
            label="Password"
            type="password"
            id="password"
            icon={<LockIcon />}
            autoComplete="new-password"
            onChange={(e) => setPassword(e.target.value)}
            onSubmit={handleSubmit}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={phonenumber}
            margin="normal"
            required
            fullWidth
            id="phonenumber"
            label="Phone Number"
            name="Phonenumber"
            autoComplete="tel"
            icon={<SmartphoneIcon />}
            variant="outlined"
            onChange={(e) => setPhoneNumber(e.target.value)}
            onSubmit={handleSubmit}
          />
        </Grid>
        <br />
        <br />
        <br />
        <br />
        <br />
        <Grid item xs={12} className={classes.centerText}>
          <Button
            type="submit"
            size="large"
            variant="contained"
            disabled={loading}
            style={{
              maxWidth: '400px',
              maxHeight: '400px',
              minWidth: '100px',
              minHeight: '50px',
              textTransform: 'none',
            }}
            color="primary"
            onClick={handleSubmit}
          >
            <Typography component="h3" variant="h5">
              <b>Sign up</b>
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={12} className={classes.centerText}></Grid>
        <Grid item xs={12} className={classes.centerText}>
          Already have an account?{' '}
          <Link href="/driver/login">
            <b>Login</b>
          </Link>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Signup;
