import React from 'react';

// Material-UI
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

// Icons
import HomeIcon from '@mui/icons-material/Home';
// TODO replace with a better icon
import SearchIcon from '@mui/icons-material/Search';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import FeedbackIcon from '@mui/icons-material/Feedback';

export default function CustomBottomNavigation({ value, updatePage }) {
  const handleChange = (event, newValue) => {
    if (newValue !== value) {
      updatePage(newValue);
    }
  };

  return (
    <div>
      <BottomNavigation value={value} onChange={handleChange}>
        <BottomNavigationAction
          label="Home"
          value={'home'}
          icon={<HomeIcon />}
        />
        <BottomNavigationAction
          label="Search"
          value={'search'}
          icon={<SearchIcon style={{ fontSize: 30 }} />}
        />
        <BottomNavigationAction
          label="Library"
          value={'library'}
          icon={<LibraryMusicIcon />}
        />
        <BottomNavigationAction
          label="Feedback"
          value={'feedback'}
          icon={<FeedbackIcon />}
        />
      </BottomNavigation>
    </div>
  );
}
