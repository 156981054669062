import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import queryString from 'query-string';

import makeStyles from '@mui/styles/makeStyles';
import { Box } from '@mui/material';

// Components
import BottomNavigation from './components/BottomNavigaton';
import Home from './pages/Home';
import Search from './pages/Search';
import NowPlaying from './pages/NowPlaying';
import FullAlbum from './pages/FullAlbum';
import ExploreCategory from './pages/ExploreCategory';
import UnderDev from './pages/UnderDev';

import { API_URL } from '../config';
import { CircularProgress } from '@mui/material';

const useStyles = makeStyles({
  stickToBottom: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
  },
});

const pages = {
  home: 0,
  search: 1,
  library: 2,
  feedback: 3,
  album: 4,
  playlist: 5,
  category: 6,
  artist: 7,
};

export default function Play() {
  const classes = useStyles();

  const [location, setLocation] = useState();

  const [loading, setLoading] = useState(true);

  // Used in home
  const [topPlaylists, setTopPlaylists] = useState();
  const [newMusic, setNewMusic] = useState();
  const [categories, setCategories] = useState();

  // Used for displaying an album
  const [currentMusicView, setCurrentMusicView] = useState();

  // Used for searching
  const [searchResults, setSearchResults] = useState();

  // Used for now playing
  const [nowPlaying, setNowPlaying] = useState();

  const q = queryString.parse(window.location.search);
  const history = useHistory();

  useEffect(() => {
    checkPlayer()
      .then(() => {
        updatePage();
      })
      .then(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function that gets to the correct tab of the rider interface
  function updatePage(page) {
    if (page) {
      setLocation(pages[page.split('/')[0]]);
      window.history.pushState(
        {},
        '',
        `/play/${page}?driver=${q.driver}&rider=${q.rider}`
      );
    } else {
      const page = window.location.pathname.split('/');
      if (pages[page[2]]) {
        setLocation(pages[page[2]]);
      } else {
        setLocation(0);
      }
      if (page[3]) {
        console.log(1);
        if (!currentMusicView?.id) {
          setCurrentMusicView({
            id: page[3],
            type: page[2],
          });
          getSpotifyData(
            page[2] + '/details',
            page[3],
            false,
            setCurrentMusicView
          );
        }
      }
      if (q.search && page[2] === 'search') {
        handleSearch(q.search);
      }
    }
  }

  // checks to see if the player is valid and if not reroutes
  async function checkPlayer() {
    if (!q.driver || !q.rider) {
      // TODO: #9 change this to a disconnect page that gives the option to review (and below)
      history.push('/');
    }
    console.log('the q = ', q);
    axios
      .post(API_URL + '/connect/pair/check/', {
        user: q.rider,
        driver: q.driver,
      })
      .catch((error) => {
        history.push('/');
        console.log('error from /connect/pair/check/ = ', error.response);
      });
  }

  // Gets info on a type i.e. playlist and an id
  async function getSpotifyData(type, id, updateNowPlaying = false, setReturn) {
    const dat = {
      id: id,
      user: q.rider,
      driver: q.driver,
    };

    axios
      .post(`${API_URL}/connect/${type}/`, dat)
      .then((response) => {
        if (setReturn) setReturn(response.data);
        if (updateNowPlaying) {
          setTimeout(function () {
            getSpotifyData('nowPlaying', '', false, setNowPlaying);
          }, 500);
          setTimeout(function () {
            getSpotifyData('nowPlaying', '', false, setNowPlaying);
          }, 2000);
          setTimeout(function () {
            getSpotifyData('nowPlaying', '', false, setNowPlaying);
          }, 10000);
        }
      })
      .catch((error) => {
        checkPlayer();
        console.log(`error from /connect/${type}/ = `, error.response);
      });
  }

  // Get reccomended music --> new music, top playlists, and categories from spotify
  function getReccomended() {
    axios
      .post(API_URL + '/connect/recommended/', {
        user: q.rider,
        driver: q.driver,
      })
      .then((response) => {
        setTopPlaylists(response.data.top);
        setNewMusic(response.data.new);
        setCategories(response.data.categories);
      })
      .catch((error) => {
        checkPlayer();
        console.log('error from /connect/recommend/', error.response?.data);
      });
  }

  // Searches for music and sets it to search results
  function handleSearch(val) {
    if (val) {
      getSpotifyData('search', val, false, setSearchResults);
      setLocation(pages['search']);
      if (q?.search !== val) {
        window.history.pushState(
          {},
          '',
          `/play/search?driver=${q.driver}&rider=${q.rider}&search=${val}`
        );
      }
    }
  }

  window.addEventListener(
    'popstate',
    function (event) {
      updatePage();
    },
    false
  );

  if (loading) {
    return (
      <>
        <CircularProgress />
        <BottomNavigation
          value={window.location.pathname.split('/')[2]}
          updatePage={updatePage}
        />
      </>
    );
  }

  return (
    <>
      {location === 0 && (
        <Home
          topPlaylists={topPlaylists}
          newMusic={newMusic}
          categories={categories}
          setLocation={updatePage}
          setCurrentMusicView={setCurrentMusicView}
          getReccomended={getReccomended}
          handleSearch={handleSearch}
        />
      )}
      {location === 1 && (
        <Search
          setLocation={updatePage}
          setCurrentMusicView={setCurrentMusicView}
          getSpotifyData={getSpotifyData}
          searchResults={searchResults}
          handleSearch={handleSearch}
        />
      )}
      {location === 2 && <UnderDev />}
      {location === 3 && <UnderDev />}
      {(location === 4 || location === 5) && (
        <FullAlbum details={currentMusicView} getSpotifyData={getSpotifyData} />
      )}
      {location === 6 && (
        <ExploreCategory
          details={currentMusicView}
          setCurrentMusicView={setCurrentMusicView}
          setLocation={updatePage}
          getSpotifyData={getSpotifyData}
        />
      )}
      {location === 7 && (
        // TODO: #11 make artists its own screen with options of albums/playlists etc
        <FullAlbum details={currentMusicView} getSpotifyData={getSpotifyData} />
      )}
      <br />
      <br />
      <br />
      <Box className={classes.stickToBottom}>
        <NowPlaying
          getSpotifyData={getSpotifyData}
          nowPlaying={nowPlaying}
          setNowPlaying={setNowPlaying}
        />
        <BottomNavigation
          value={window.location.pathname.split('/')[2]}
          updatePage={updatePage}
        />
      </Box>
    </>
  );
}
