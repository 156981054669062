import React, { useEffect } from 'react';
import { Button, Grid, Typography } from '@mui/material';

export default function NotFound() {
  useEffect(() => {
    document.title = '404 - Not Found';
  }, []);

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      style={{ minHeight: '100vh' }}
      spacing={1}
    >
      <Grid item xs={12}>
        <Typography variant="h1" fontWeight={500}>
          Opps
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">Something went wrong.</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body" fontWeight={10}>
          Error 404 Page Not Found
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" href="/">
          <b>Go Home</b>
        </Button>
      </Grid>
    </Grid>
  );
}
