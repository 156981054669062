import React, { useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    //margin: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: theme.spacing(0),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));

export default function TextField({
  label = '',
  id = '',
  autoComplete = '',
  onChange = (e) => {},
  onSubmit = (e) => {},
  required = false,
  type = 'text',
  autoFocus = false,
  icon = <SearchIcon />,
}) {
  const classes = useStyles();

  return (
    <Paper component="form" className={classes.root}>
      <div className={classes.iconButton}>{icon}</div>
      <InputBase
        className={classes.input}
        placeholder={label}
        type={type}
        inputProps={{ 'aria-label': 'Search music' }}
        autoFocus={autoFocus}
        id={id}
        fullWidth
        autoComplete={autoComplete}
        required={required}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onSubmit(e);
            e.preventDefault();
          }
        }}
        onChange={(e) => {
          onChange(e);
          e.preventDefault();
        }}
      />
    </Paper>
  );
}
