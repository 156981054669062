import queryString from 'query-string';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { auth, data } from '../config';
import { useAuth } from '../contexts/AuthContext';
import { CLIENT_ID, REDIRECT_URI, CLIENT_SECRET, API_URL } from '../config';
import axios from 'axios';

const Callback = () => {
  const { currentUser } = useAuth();
  const history = useHistory();
  const values = queryString.parse(window.location.search);
  const display = 'Do not refresh. Linking your spotify...';
  const location = useLocation();

  useEffect(() => {
    linkSpotify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function linkSpotify() {
    const goto = '/driver/completeprofile';
    const redirect = REDIRECT_URI;
    const incorrect = '/driver/addspotify/error';

    const params = new URLSearchParams(location.search);

    const dat = {
      email: currentUser.email,
      code: values.code,
      redirectURI: redirect,
    };
    if (!values.code) {
      history.push(incorrect);
    }
    axios
      .post(API_URL + '/driver/addSpotify/', dat)
      .then((response) => {
        history.push(goto);
      })
      .catch((error) => {
        if (error.response?.data?.status_code === 401) {
          history.push(
            `${goto}?error=Please purchase Spotify Premium before using`
          );
        }
        console.log(error.response?.data?.message);
        history.push(incorrect);
      });
  }

  return <div>{display}</div>;
};

export default Callback;
