import React from 'react';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import Skeleton from '@mui/material/Skeleton';
import Image from 'mui-image';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(17),
    },
  },
}));

export default function Album({ album, setLocation, setCurrentMusicView }) {
  const classes = useStyles();

  function handleAlbumClick() {
    setCurrentMusicView(album);
    if (album.type) setLocation(album.type + '/' + album.id);
    else setLocation('category/' + album.id);
  }

  // TODO #10 add a by for albums and maybe playlists
  return (
    <div className={classes.root}>
      <Box onClick={handleAlbumClick} elevation={0}>
        {album?.images ? (
          <Image fit="scale-down" height={120} src={album?.images[0]?.url} />
        ) : album?.icons ? (
          <Image fit="scale-down" height={120} src={album?.icons[0].url} />
        ) : (
          <Skeleton
            variant="rectangular"
            animation="wave"
            fullWidth
            height={120}
          />
        )}
        <Typography
          fullWidth
          align="center"
          noWrap
          variant="body2"
          display="block"
          gutterBottom
        >
          {album?.name || <Skeleton />}
        </Typography>
      </Box>
    </div>
  );
}
