import React from 'react';
import { AuthProvider } from './contexts/AuthContext';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Signup from './driver/Signup';
import Dashboard from './driver/Dashboard';
import Login from './driver/Login';
import PrivateRoute from './PrivateRoute';
import ForgotPassword from './driver/ForgotPassword';
import UpdateProfile from './driver/UpdateProfile';
import AddSpotify from './driver/AddSpotify';
import Callback from './driver/Callback';
import Pair from './player/pages/Pair';
import Play from './player/Play';
import Legal from './player/Legal';
import VerifyNumber from './driver/VerifyNumber';
import NotFound from './components/Errors/NotFound';
import SiteDown from './components/Errors/SiteDown';

import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  adaptV4Theme,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { lightBlue } from '@mui/material/colors';
import { useAuth } from './contexts/AuthContext';
import CompleteProfile from './driver/CompleteProfile';
import UpdatePhone from './driver/UpdatePhone';
import SignupData from './driver/SignupData';
import Logout from './driver/Logout';

import { Button } from '@mui/material';
import { withTheme } from '@emotion/react';
import { appDown } from './config';

const theme = createTheme(
  adaptV4Theme({
    palette: {
      mode: 'dark',
      primary: lightBlue,
      secondary: {
        main: '#4caf50',
      },
      thirdary: {
        main: '#FFFFFF',
      },
      background: {
        default: '#2c2b3f',
        paper: '#444264',
      },
    },
  })
);

export default function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {appDown ? <SiteDown /> : (
        <Router>
          <AuthProvider>
            <Switch>
              <Route
                path="/feedback"
                component={() => {
                  window.location.href =
                    'https://cmdn.typeform.com/to/oaJOzq2g';
                  return null;
                }}
              />
              <Route
                path="/waitlist"
                component={() => {
                  window.location.href =
                    'https://cmdn.typeform.com/to/TFVYfPDN';
                  return null;
                }}
              />
              <Route
                path="/bc"
                component={() => {
                  window.location.href = 'https://ridejam.app/';
                  return null;
                }}
              />
              <Route
                path="/di"
                component={() => {
                  window.location.href = 'https://ridejam.app/driver';
                  return null;
                }}
              />
              <Route exact path="/legal">
                <Legal />
              </Route>
              <Route path="/connect">
                <Pair />
              </Route>
              <Route exact path="/">
                <Pair />
              </Route>
              <Route path="/play">
                <Play />
              </Route>
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <PrivateRoute exact path="/driver" component={Dashboard} />
              <PrivateRoute
                exact
                path="/update-profile"
                component={UpdateProfile}
              />
              <Route exact path="/driver/login" component={Login} />
              <Route exact path="/driver/logout">
                <Logout />
              </Route>
              {/* first step in signup process */}
              <Route exact path="/driver/signup">
                <Signup />
              </Route>
              {/* second step in signup process (verifys phone and redirects to data) */}
              <PrivateRoute exact path="/driver/verifynumber">
                <VerifyNumber />
              </PrivateRoute>
              <PrivateRoute exact path="/driver/update/phone">
                <UpdatePhone />
              </PrivateRoute>
              {/* third step in signup proccess (collects data and redirects to addspotify) */}
              <PrivateRoute exact path="/driver/singup/data">
                <SignupData />
              </PrivateRoute>
              {/* forth step in signup process (adds spotify and redirects to completeprofile) */}
              <PrivateRoute exact path="/driver/addSpotify">
                <AddSpotify returnLocation={'driver/profile'} />
              </PrivateRoute>
              <PrivateRoute exact path="/driver/addSpotify/error">
                <Button
                  variant="contained"
                  color="primary"
                  href="/driver/addSpotify"
                >
                  Link Spotify
                </Button>
              </PrivateRoute>
              <PrivateRoute path="/callback">
                <Callback />
              </PrivateRoute>
              {/* completes driver profile and redirects to dashboard */}
              <PrivateRoute exact path="/driver/completeprofile">
                <CompleteProfile />
              </PrivateRoute>
              <Route>
                <NotFound />
              </Route>
            </Switch>
          </AuthProvider>
        </Router>
        )}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
