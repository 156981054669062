import React from 'react';

import Grid from '@mui/material/Grid';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import makeStyles from '@mui/styles/makeStyles';

import Album from './Album';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'left',
  },
  gridList: {
    display: 'flex',
    flexDirection: 'row',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
}));

export default function Albums({
  albums,
  setLocation,
  setCurrentMusicView,
  verticle,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {!verticle ? (
        <ImageList className={classes.gridList}>
          {albums?.items
            ? albums?.items?.map((item) => (
                <ImageListItem key={item.id}>
                  <Album
                    setCurrentMusicView={setCurrentMusicView}
                    setLocation={setLocation}
                    album={item}
                  />
                </ImageListItem>
              ))
            : [0, 0, 0, 0, 0, 0, 0, 0, 0].map((item) => <Album album={item} />)}
        </ImageList>
      ) : (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          {albums?.items
            ? albums?.items?.map((item) => (
                <Grid item container xs={5} sm={3} md={2} xl={1}>
                  <Album
                    setCurrentMusicView={setCurrentMusicView}
                    setLocation={setLocation}
                    album={item}
                  />
                </Grid>
              ))
            : [0, 0, 0, 0, 0, 0, 0, 0, 0].map((item) => (
                <Grid item container xs={5} sm={3} md={2} xl={1}>
                  <Album album={item} />
                </Grid>
              ))}
        </Grid>
      )}
    </div>
  );
}
