import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Fab from '@mui/material/Fab';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { API_URL } from '../config';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MailPackage from './MailPackage';

export default function Dashboard() {
  const { currentUser, logout } = useAuth();
  const [driverProfile, setDriverProfile] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [conectInstructions, setConectInstructions] = useState(false);
  const history = useHistory();

  useEffect(() => {
    getDriverProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getDriverProfile() {
    axios
      .get(API_URL + '/driver/profile/' + '?email=' + currentUser.email)
      .then((response) => {
        setDriverProfile(response.data);
        //    checkDriverProfile(response.data);
      })
      .catch((error) => {
        console.log(error.response?.data?.message);
      });
  }

  function updateOnline() {
    setLoading(true);
    const dat = {
      uid: currentUser.uid,
      status: !driverProfile.online,
    };

    axios
      .post(API_URL + '/driver/status/update/', dat)
      .then((response) => {
        setDriverProfile(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response?.data?.message);
        setLoading(false);
      });
  }

  if (!driverProfile) {
    return <CircularProgress />;
  }

  return (
    <div style={{ padding: '0.4rem' }}>
      <div
        style={{
          position: 'absolute',
          left: '5px',
          top: '0px',
        }}
      >
        <img
          style={{ width: '150px' }}
          src={'/driver.png'}
          alt="Logo-Dark-Left"
        />
      </div>
      <div
        style={{
          position: 'absolute',
          right: '10px',
          top: '10px',
        }}
      >
        <Button href={'/driver/logout'}>Log out</Button>
      </div>
      <div>
        <Dialog
          open={conectInstructions}
          onClose={() => setConectInstructions(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Rider connect instructions'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Rider connect instructions allow for riders to easily connect to
              your in car music. Simpily print out and display these connection
              instructions or request instructions to be mailed to you below.
            </DialogContentText>
            <h4>Print</h4>
            <Button
              target="_blank"
              href="/rider_connection_instructoins_01.pdf"
            >
              Open
            </Button>
            <h4>Mail</h4>
            <MailPackage setError={setError} setLoading={setLoading} />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setConectInstructions(false)}
              color="primary"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* TODO add the connectoin stuff here as a dialog or someshit <ConnectInstructions /> */}
      </div>
      <br />
      <br />
      <br />
      <Card raised={true}>
        <Box p={1}>
          <Typography color="textSecondary" gutterBottom>
            Profile
          </Typography>
          <Typography>
            Name: {driverProfile.firstname} {driverProfile.lastname}
            <br />
            Phone: {driverProfile.phone || ''}
            <br />
            Email: {driverProfile.email}
            <br />
            Driver ID: {driverProfile.username}
            <br />
            {/* Personal URL: <Link href={`https://songwave.app/connect/${driverProfile.driverid}`}>songwave.app/connect/cnking</Link> */}
          </Typography>
        </Box>
      </Card>
      <br />
      <Button
        style={{ backgroundColor: '#00F0FF' }}
        variant="contained"
        fullWidth
        onClick={() => setConectInstructions(true)}
      >
        <Typography>Get Rider Connection instructions</Typography>
      </Button>
      <br />
      <br />
      <Card raised={true}>
        <Box p={1}>
          <Typography color="textSecondary" gutterBottom>
            How to go online!
          </Typography>
          1. Link your Spotify premium account.
          <br />
          2. Request free connection instructions here or print your own off
          here.
          <br />
          3. Go online below.
          <br />
          4. Open Spotify and begin playing music.
          <br />
          4. When riders scan your QR code, tap your NFC tag, or enter your
          username they will be able to choose what music to play from your
          Spotify until you go offline or get a new rider.
        </Box>
      </Card>
      <br />
      {/* <Card raised={true}>
                <Box p={1}>
                    <Typography color="textSecondary" gutterBottom>
                    Connections
                    </Typography>
                    Coming soon
                </Box>
            </Card>
            <br/> */}
      <div style={{ textAlign: 'center' }}>
        <Fab
          style={{
            width: '200px',
            height: '200px',
            backgroundColor: driverProfile.online ? 'red' : 'green',
            color: 'white',
          }}
          aria-label="online/offline"
          onClick={() => updateOnline()}
          disabeld={loading}
        >
          {driverProfile.online ? (
            <h1>
              GO
              <br />
              OFFLINE
            </h1>
          ) : (
            <h1>
              GO
              <br />
              ONLINE
            </h1>
          )}
        </Fab>
      </div>
      <br />
      <br />
    </div>
  );
}
