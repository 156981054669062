import React, { useState, useRef, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';
import queryString from 'query-string';

import { FormControlLabel } from '@mui/material';

import { API_URL } from '../../config';
import axios from 'axios';

function Pair() {
  // const [phoneNumber, setPhoneNumber] = useState()
  const q = queryString.parse(window.location.search);
  const phoneNumberRef = useRef();
  const driverIDRef = useRef();
  const checkedTermsRef = useRef();
  // const [driverID, setDriverID] = useState()
  const [error, setError] = useState('');
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (q.driver) {
      driverIDRef.current.value = q.driver;
    } else if (q.d) {
      driverIDRef.current.value = q.d;
    }
  }, [q]);
  function handleCheck(e) {
    setChecked(e.target.checked);
  }
  function handleSubmit() {
    setError('');
    setLoading(true);
    if (!phoneNumberRef.current.value) {
      setLoading(false);
      setError('Valid phone number required');
    } else if (phoneNumberRef.current.value.length < 10) {
      setLoading(false);
      setError('Valid phone number required');
    } else if (!driverIDRef.current.value) {
      setLoading(false);
      setError('Please enter a driver ID');
    } else if (!checked) {
      setLoading(false);
      setError('Please agree to terms of service');
    } else {
      const dat = {
        user: phoneNumberRef.current.value,
        driver: driverIDRef.current.value.toLowerCase(),
      };

      console.log('data = ', dat, API_URL);

      axios
        .post(API_URL + '/connect/pair/', dat)
        .then((response) => {
          history.push(
            `/play/home?driver=${driverIDRef.current.value.toLowerCase()}&rider=${
              phoneNumberRef.current.value
            }`
          );
        })
        .catch((error) => {
          setError(error.response?.data?.message);
          setLoading(false);
        });
    }
  }

  return (
    <>
      <div
        style={{
          position: 'absolute',
          left: '50%',
          top: '10%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <img
          style={{ maxWidth: '400px', padding: 1, alignSelf: 'center' }}
          src={'/logo1_white_small.png'}
          alt="Logo"
        />
      </div>
      <div
        style={{
          position: 'absolute',
          left: '80%',
          top: '90%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <img
          style={{ maxHeight: '100px' }}
          src={
            'https://storage.googleapis.com/songwave.appspot.com/POWERED%20BY.png'
          }
          alt="Logo"
        />
      </div>
      <div
        style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        {error ? <Alert severity="error">{error}</Alert> : ''}
        <Box pt={1}>
          <TextField
            fullWidth
            label="Phone Number"
            variant="outlined"
            name="phoneNumber"
            type="tel"
            margin="normal"
            inputRef={phoneNumberRef}
            onSubmit={handleSubmit}
          />
        </Box>
        <Box pt={1}>
          <TextField
            fullWidth
            variant="outlined"
            name="driverId"
            label="Driver ID"
            type="text"
            inputRef={driverIDRef}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit();
              }
            }}
            onSubmit={handleSubmit}
          />
        </Box>
        <Box pt={1}>
          <FormControlLabel
            control={
              <Checkbox
                name="checkedTerms"
                color="primary"
                inputRef={checkedTermsRef}
                onChange={handleCheck}
                onSubmit={handleSubmit}
              />
            }
            label={
              <>
                I agree to <Link to="/legal">terms and conditions</Link>
              </>
            }
          />
        </Box>
        <Box pt={1}>
          <Button
            fullWidth
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={loading}
            size="large"
          >
            Connect!
          </Button>
        </Box>
      </div>
      <div
        style={{
          position: 'absolute',
          left: '20%',
          top: '90%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Button
          variant="contained"
          component={Link}
          to="/driver/login"
          color="secondary"
        >
          Driver login
        </Button>
      </div>
    </>
  );
}

export default Pair;
