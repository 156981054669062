import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Skeleton from '@mui/material/Skeleton';
import Image from 'mui-image';
// import ShuffleIcon from '@mui/icons-material/Shuffle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Track from '../components/Track';
import { Fab } from '@mui/material';

export default function FullAlbum({ details, getSpotifyData }) {
  const [tracks, setTracks] = useState(details?.tracks);
  const [expandedPanel, setExpandedPanel] = useState(-1);

  useEffect(() => {
    async function fetchData() {
      if (!tracks?.items && details.type && details.id) {
        getSpotifyData(details.type, details.id, false, setTracks);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details]);

  function playAlbum() {
    getSpotifyData('play', details.uri, true);
  }

  return (
    <>
      <Box zIndex="modal" position="fixed" top={10} left={10}>
        <Fab color="default" size="small" onClick={() => window.history.back()}>
          <ArrowBackIcon />
        </Fab>
      </Box>
      <Box m={2} display="flex" flexDirection="column" alignItems="center">
        <Box width={'175px'}>
          {details?.images ? (
            <Image src={details?.images[0]?.url} />
          ) : details?.icons ? (
            <Image src={details?.icons[0].url} />
          ) : (
            <Skeleton
              variant="rectangular"
              animation="wave"
              fullWidth
              height={120}
            />
          )}
        </Box>
        <Box
          fontWeight="fontWeightBold"
          m={1}
          p={0}
          fontSize="h5.fontSize"
          textAlign={'center'}
        >
          {details?.name || <Skeleton />}
        </Box>
        {details.type !== 'artist' && (
          <Box m={-1} fontSize="fontSize">
            By.{' '}
            {details?.owner?.display_name ||
              details?.artists?.map((artist, idx) =>
                idx === 0 ? artist.name : ' & ' + artist.name
              )}
          </Box>
        )}
        <Box p={1} fontSize="fontSize">
          {details?.description}
        </Box>
        <Box p={1}>
          <Button
            color="primary"
            variant="contained"
            startIcon={<PlayArrowIcon />}
            onClick={() => playAlbum()}
          >
            Play
          </Button>
        </Box>
      </Box>
      <Box fullWidth>
        {tracks?.items || tracks?.tracks
          ? (tracks?.items || tracks?.tracks)?.map((track, idx) => (
              <Track
                track={track.track || track}
                handlePlay={(uri) => getSpotifyData('play', uri, true)}
                handleQueue={(uri) => getSpotifyData('queue', uri, true)}
                fromAlbum={details?.type === 'album'}
                expandedPanel={expandedPanel === idx}
                setExpandedPanel={() => {
                  if (idx === expandedPanel) {
                    setExpandedPanel(-1);
                  } else {
                    setExpandedPanel(idx);
                  }
                }}
              />
            ))
          : [0, 0, 0, 0, 0, 0, 0, 0].map((track, idx) => (
              <Track
                track={track.track}
                fromAlbum={false}
                expandedPanel={expandedPanel === idx}
                setExpandedPanel={() => {
                  if (idx === expandedPanel) {
                    setExpandedPanel(-1);
                  } else {
                    setExpandedPanel(idx);
                  }
                }}
              />
            ))}
      </Box>
      <br />
      <br />
      <br />
      <br />
    </>
  );
}
