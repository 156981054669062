import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '../Reusable/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import makeStyles from '@mui/styles/makeStyles';
import { API_URL } from '../config';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import './Driver.css';

// Icons
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { Link } from '@mui/material';

const useStyles = makeStyles({
  leftText: {
    textAlign: 'left',
  },
  rightText: {
    textAlign: 'right',
  },
  centerText: {
    textAlign: 'center',
  },
});
const VerifyNumber = () => {
  const [code, setCode] = useState('');
  const { currentUser, profile } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const classes = useStyles();

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    axios
      .post(API_URL + '/sms/verify/confirm/', {
        uid: currentUser.uid,
        code: code,
      })
      .then((response) => {
        history.push('/driver/singup/data');
      })
      .catch((error) => {
        console.log(error.response?.data?.message);
        setLoading(false);
      });
  }

  useEffect(() => {
    if (!currentUser.uid) history.push('/signup');
    axios
      .post(API_URL + '/sms/verify/', { uid: currentUser.uid })
      .then((response) => {
        console.log(response);
        if (response.status === 208) {
          history.push('/driver');
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response?.data?.message + 'error');
        setLoading(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading || !profile.phone) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <Container maxWidth="sm">
      <Grid container spacing={1}>
        <Grid item xs={8}>
          {/* TODO: Center the text vertically without breaks  */}
          <br />
          <br />
          <Typography component="h1" variant="h2">
            <b>Verify</b>
          </Typography>
        </Grid>

        <Grid item xs={4} className={classes.centerText}>
          <img
            style={{ maxWidth: '130px', padding: 0.2, alignSelf: 'center' }}
            src={'/ridejamlogo.png'}
            alt="Logo"
          />
        </Grid>

        <Grid item xs={12}>
          <br />
          <Typography>
            Please enter the verification code sent to <b>{profile.phone}</b>.
          </Typography>
          <br />
        </Grid>
        <br />
        {error !== '' && (
          <Grid item xs={12}>
            <Alert severity="error">{error}</Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            value={code}
            margin="normal"
            required
            fullWidth
            id="code"
            label="code"
            name="code"
            autoComplete="Code"
            autoFocus={true}
            icon={<SmartphoneIcon />}
            variant="outlined"
            onChange={(e) => setCode(e.target.value)}
            onSubmit={handleSubmit}
          />
        </Grid>
        <Grid item xs={12} className={classes.centerText}>
          <br />
          <Link href="/driver/update/phone">
            <Typography>
              <b>Edit phone number</b>
            </Typography>
          </Link>
        </Grid>
        <Grid item xs={12} className={classes.centerText}>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Button
            type="submit"
            size="large"
            variant="contained"
            disabled={loading || code.trim().length === 0}
            style={{
              maxWidth: '400px',
              maxHeight: '400px',
              minWidth: '150px',
              minHeight: '55px',
              textTransform: 'none',
            }}
            color="primary"
            onClick={handleSubmit}
          >
            <Typography component="h3" variant="h5">
              <b>Verify</b>
            </Typography>
          </Button>
        </Grid>
        <br />
        <br />
      </Grid>
    </Container>
  );
};

export default VerifyNumber;
