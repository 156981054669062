import React from 'react';

// Material UI
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Image from 'mui-image';
import Typography from '@mui/material/Typography';
import ExplicitIcon from '@mui/icons-material/Explicit';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';

function Track({
  track,
  fromAlbum,
  handlePlay,
  handleQueue,
  expandedPanel,
  setExpandedPanel,
}) {
  function localHandlePlay() {
    handlePlay(track.uri);
  }

  function localHandleQueue() {
    handleQueue(track.uri);
  }

  return (
    <Accordion
      style={{ backgroundColor: 'transparent', boxShadow: 'none' }}
      expanded={expandedPanel}
    >
      <Button onClick={() => setExpandedPanel()} fullWidth color="thirdary">
        <Grid
          container
          item
          xs={12}
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {fromAlbum || (
            <Grid item xs={2} sm={1}>
              {track?.album?.images[1].url ? (
                <Image src={track?.album?.images[0].url} />
              ) : (
                <Skeleton />
              )}
            </Grid>
          )}
          <Grid item xs={fromAlbum ? 11 : 9} wrap="nowrap">
            <Typography
              noWrap
              align="left"
              style={{ fontWeight: 600, textTransform: 'none' }}
            >
              {track?.name || <Skeleton />}
            </Typography>
            <Typography noWrap align="left" style={{ textTransform: 'none' }}>
              {track?.artists ? (
                track.artists.map((artist, idx) =>
                  idx === 0 ? artist.name : ' & ' + artist.name
                )
              ) : (
                <Skeleton />
              )}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Box>{track?.explicit ? <ExplicitIcon /> : ''}</Box>
          </Grid>
        </Grid>
      </Button>
      <>
        <AccordionDetails>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Button
                onClick={localHandlePlay}
                fullWidth
                variant="contained"
                color="secondary"
              >
                Play now
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={localHandleQueue}
                fullWidth
                variant="contained"
                color="primary"
              >
                Add to queue
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </>
    </Accordion>
  );
}

export default Track;
