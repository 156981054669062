import React from 'react';
import { CLIENT_ID, REDIRECT_URI } from '../config';

const AddSpotify = ({ returnLocation }) => {
  const uri = REDIRECT_URI;

  window.location.href =
    'https://accounts.spotify.com/authorize?client_id=' +
    CLIENT_ID +
    '&response_type=code&scope=app-remote-control,user-modify-playback-state,user-read-email,user-read-private,user-read-playback-state&redirect_uri=' +
    uri;

  return null;
};

export default AddSpotify;
