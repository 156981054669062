import React, { useEffect, useState } from 'react';

// Material ui
import { Box, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

// Components
import Albums from '../components/Albums';
import SearchField from '../../Reusable/SearchField';

export default function Home({
  topPlaylists,
  newMusic,
  categories,
  setLocation,
  setCurrentMusicView,
  getReccomended,
  handleSearch,
}) {
  const [searchEditable, setSearchEditable] = useState(false);

  useEffect(() => {
    if (!topPlaylists || !newMusic || !categories) {
      getReccomended();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {!searchEditable && (
        <div
          style={{
            position: 'absolute',
            right: '5px',
            top: '0px',
          }}
        >
          <img
            style={{ width: '150px' }}
            src={'/logo1_white.png'}
            alt="Logo-Light"
          />
        </div>
      )}
      <Box m={1}>
        {searchEditable ? (
          <SearchField handleSearch={handleSearch} />
        ) : (
          <IconButton onClick={() => setSearchEditable(true)} size="large">
            <SearchIcon />
          </IconButton>
        )}
      </Box>
      <Box>
        <Box fontWeight="fontWeightBold" m={1} fontSize={20}>
          Top Playlists
        </Box>
        <Albums
          setCurrentMusicView={setCurrentMusicView}
          setLocation={setLocation}
          albums={topPlaylists?.playlists}
        />
      </Box>
      <Box>
        <Box fontWeight="fontWeightBold" m={1} fontSize={20}>
          New Music
        </Box>
        <Albums
          setCurrentMusicView={setCurrentMusicView}
          setLocation={setLocation}
          albums={newMusic?.albums}
        />
      </Box>
      <Box>
        <Box fontWeight="fontWeightBold" m={1} fontSize={20}>
          Categories
        </Box>
        <Albums
          setCurrentMusicView={setCurrentMusicView}
          setLocation={setLocation}
          albums={categories?.categories}
        />
      </Box>
      <br />
      <br />
      <br />
    </div>
  );
}
