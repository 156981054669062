import React, { useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import SearchField from '../../Reusable/SearchField';
import Track from '../components/Track';
import Albums from '../components/Albums';

function Search({
  handleSearch,
  searchResults,
  getSpotifyData,
  setLocation,
  setCurrentMusicView,
}) {
  const [editable, setEditable] = useState(false);

  // Number of each type to display
  const [songResultsLength, setSongResultsLength] = useState(5);

  // Expanded song
  const [expandedSong, setExplandedSong] = useState(-1);

  if (!editable && !searchResults) {
    return (
      <Box
        m={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="75vh"
      >
        <div
          style={{
            position: 'absolute',
            right: '5px',
            top: '0px',
          }}
        >
          <img
            style={{ width: '150px' }}
            src={
              'https://storage.googleapis.com/songwave.appspot.com/Copy%20of%20Blue%20Expressive%20Shapes%20Action%20%20Adventure%20Animated%20Logo%20(5).png'
            }
            alt="Logo-Light"
          />
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              onClick={() => setEditable(true)}
            >
              <SearchIcon /> &nbsp; Search music
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box m={1}>
      <SearchField handleSearch={handleSearch} />
      {searchResults?.tracks?.items?.length > 0 && (
        <Box>
          <Box fontWeight="fontWeightBold" m={1} fontSize={20}>
            Songs
          </Box>
          {searchResults?.tracks?.items?.map(
            (track, idx) =>
              idx < songResultsLength && (
                <Track
                  track={track}
                  handlePlay={(id) => getSpotifyData('play', id, true)}
                  handleQueue={(id) => getSpotifyData('queue', id, true)}
                  expandedPanel={expandedSong === idx}
                  setExpandedPanel={() => {
                    if (idx === expandedSong) {
                      setExplandedSong(-1);
                    } else {
                      setExplandedSong(idx);
                    }
                  }}
                />
              )
          )}
          <Button
            onClick={() => setSongResultsLength((prev) => prev + 5)}
            fullWidth
            disabled={searchResults?.tracks?.items?.length <= songResultsLength}
            variant="contained"
            color="primary"
          >
            Load more songs
          </Button>
        </Box>
      )}
      {searchResults?.albums?.items?.length > 0 && (
        <Box>
          <Box fontWeight="fontWeightBold" m={1} fontSize={20}>
            Albums
          </Box>
          <Albums
            setCurrentMusicView={setCurrentMusicView}
            setLocation={setLocation}
            albums={searchResults?.albums}
          />
        </Box>
      )}
      {searchResults?.playlists?.items?.length > 0 && (
        <Box>
          <Box fontWeight="fontWeightBold" m={1} fontSize={20}>
            Playlists
          </Box>
          <Albums
            setCurrentMusicView={setCurrentMusicView}
            setLocation={setLocation}
            albums={searchResults?.playlists}
          />
        </Box>
      )}
      {searchResults?.artists?.items?.length > 0 && (
        // TODO: when there is an artist without a picture it never stops loading
        <Box>
          <Box fontWeight="fontWeightBold" m={1} fontSize={20}>
            Artists
          </Box>
          <Albums
            setCurrentMusicView={setCurrentMusicView}
            setLocation={setLocation}
            albums={searchResults?.artists}
          />
        </Box>
      )}
      <br />
      <br />
      <br />
    </Box>
  );
}

export default Search;
