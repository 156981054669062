import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const ENV = process.env.REACT_APP_SYSTEM_ENV || 'production';

const app = firebase.initializeApp({
  apiKey: 'AIzaSyBYXRpBfzWckk-JrH8QuITA8g7X6Ukqkds',
  authDomain: 'songwave-dev.firebaseapp.com',
  databaseURL: 'https://songwave-dev-default-rtdb.firebaseio.com',
  projectId: 'songwave-dev',
  storageBucket: 'songwave-dev.appspot.com',
  messagingSenderId: '528077399332',
  appId: '1:528077399332:web:f1b27b46ab02bef02e3fb1',
  measurementId: 'G-2FJY5F5CK5',
});

// spotify
export const CLIENT_ID = '25b0a70e6ee342db99788314d08a78d0';
export const REDIRECT_URI =
  ENV === 'production'
    ? 'https://ridejam.app/callback'
    : 'http://localhost:3000/callback';
export const API_URL =
  ENV === 'production'
    ? 'https://api.ridejam.app/v1'
    : 'http://localhost:5000/v1';
export const auth = app.auth();
export const appDown = false;

export default app;
