import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Link } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '../Reusable/TextField';
import makeStyles from '@mui/styles/makeStyles';
import EmailIcon from '@mui/icons-material/Email';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
const useStyles = makeStyles({
  leftText: {
    textAlign: 'left',
  },
  rightText: {
    textAlign: 'right',
  },
  centerText: {
    textAlign: 'center',
  },
});

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const { resetPassword } = useAuth();
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  async function handleSubmit(e) {
    e.preventDefault();
    if (email.trim().length === 0 || loading) {
      return;
    }
    try {
      setError('');
      setLoading(true);
      setMessage(
        'If an account exists with that email, you will receive an email with instructions on how to reset your password.'
      );
      await resetPassword(email);
    } catch {
      setError('Invalid email');
    }
    setLoading(false);
    setEmail('');
  }

  return (
    <Container maxWidth="xs">
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <br />
          <br />
          <Typography component="h1" variant="h2">
            <b>Forgot Password</b>
          </Typography>
        </Grid>
        <br />
        {message !== '' && (
          <Grid item xs={12}>
            <Alert severity="error">
              {message}

              <Link href="/login">Login</Link>
            </Alert>
          </Grid>
        )}
        <Grid item xs={12} className={classes.centerText}>
          <TextField
            value={email}
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="Email"
            autoComplete="Email"
            autoFocus={true}
            icon={<EmailIcon />}
            variant="outlined"
            onChange={(e) => setEmail(e.target.value)}
            onSubmit={handleSubmit}
          />
        </Grid>
        <Grid item xs={12} className={classes.centerText}>
          <Button
            type="submit"
            size="large"
            variant="contained"
            disabled={loading || email.trim().length === 0}
            style={{
              maxWidth: '400px',
              maxHeight: '400px',
              minWidth: '150px',
              minHeight: '55px',
              textTransform: 'none',
            }}
            color="primary"
            onClick={handleSubmit}
          >
            Send Reset Link
          </Button>
        </Grid>
        <Grid item xs={12} className={classes.centerText}>
          Know your password?{' '}
          <Link href="/driver/login">
            <b>Login</b>
          </Link>
        </Grid>
      </Grid>
    </Container>
    /* <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Driver Password Reset</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          {message && <Alert variant="success">{message}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required />
            </Form.Group>
            <Button disable={loading ? 1 : 0} className="w-100" type="submit">
              Reset Password
            </Button>
          </Form>
          <div className="w-100 text-center mt-3">
            <Link to="/login">Login</Link>
          </div>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        Need an account? <Link to="/signup">Sing Up</Link>
      </div> */
  );
}
