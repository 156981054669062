import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export default function Logout() {
  const { currentUser, logout } = useAuth();
  const history = useHistory();
  useEffect(() => {
    logout().then(() => {
      history.push('/driver/login');
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return <div>Logging out...</div>;
}
