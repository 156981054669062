import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export default function SignupData() {
  const history = useHistory();

  useEffect(() => {
    history.push('/driver/addspotify');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return <div>Redirecting to Spotify...</div>;
}
