import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Link, useHistory } from 'react-router-dom';
import TextField from '../Reusable/TextField';

import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Alert from '@mui/material/Alert';

// Icons
import LockIcon from '@mui/icons-material/Lock';
import EmailIcon from '@mui/icons-material/Email';

import './Driver.css';

const useStyles = makeStyles({
  leftText: {
    textAlign: 'left',
  },
  rightText: {
    textAlign: 'right',
  },
  centerText: {
    textAlign: 'center',
  },
});

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login, currentUser } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  if (currentUser) {
    history.push('/driver');
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (email.length < 4 || password.length < 1) {
      setError('Not long enough');
    } else {
      try {
        setError('');
        setLoading(true);
        await login(email, password);
        history.push('/driver');
      } catch {
        setError('Invalid email or password');
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <Container maxWidth="sm">
      <Grid container spacing={1}>
        <Grid item xs={12} className={classes.centerText}>
          <img
            style={{ maxWidth: '400px', padding: 0.2, alignSelf: 'center' }}
            src={'/ridejamlogo.png'}
            alt="Logo"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography component="h1" variant="h2">
            <b>Login</b>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography className={'bold'} component="h2" variant="h6">
            Please sign in to continue.
          </Typography>
        </Grid>
        <br />
        <br />
        {error !== '' && (
          <Grid item xs={12}>
            <Alert severity="error">{error}</Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            value={email}
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus={true}
            icon={<EmailIcon />}
            variant="outlined"
            onChange={(e) => setEmail(e.target.value)}
            onSubmit={handleSubmit}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={password}
            margin="normal"
            variant="outlined"
            required
            name="password"
            label="Password"
            type="password"
            id="password"
            icon={<LockIcon />}
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
            onSubmit={handleSubmit}
          />
        </Grid>
        <br />
        <br />
        <br />
        <br />
        <br />
        <Grid item xs={12} className={classes.centerText}>
          <Button
            type="submit"
            size="large"
            variant="contained"
            disabled={loading}
            style={{
              maxWidth: '300px',
              maxHeight: '300px',
              minWidth: '100px',
              minHeight: '50px',
              textTransform: 'none',
            }}
            color="primary"
            onClick={handleSubmit}
          >
            <Typography component="h3" variant="h5">
              <b>Login</b>
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={12} className={classes.centerText}></Grid>
        <Grid item xs={12} className={classes.centerText}>
          <Link to="/forgot-password" variant="body2">
            {/* TODO: add the forgot password shit */}
            Forgot password?
          </Link>
        </Grid>
        <Grid item xs={12} className={classes.centerText}></Grid>
        <Grid item xs={12} className={classes.centerText}>
          Don't have an account?{' '}
          <Link to="/driver/signup">
            <b>Sign Up</b>
          </Link>
        </Grid>
      </Grid>
    </Container>
  );
}
