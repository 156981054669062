import React, { useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function SearchField({ handleSearch }) {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState();

  return (
    <Paper component="form" className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder="Search"
        inputProps={{ 'aria-label': 'Search music' }}
        autoFocus={true}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            handleSearch(e.target.value);
          }
        }}
        onChange={(e) => {
          setSearchValue(e.target.value);
        }}
      />
      <Divider className={classes.divider} orientation="vertical" />
      <IconButton
        type="submit"
        className={classes.iconButton}
        aria-label="search"
        onClick={(e) => {
          e.preventDefault();
          handleSearch(searchValue);
        }}
        size="large"
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}
